import React from 'react'
import { connect } from 'react-redux'
import GridChart from '../GridChart'
import GradientBar from '../GradientBar'
import NoChartData from '../../../../uiKit/NoChartData'
import { noDataText } from '../../constants/chartsTexts'
import { color, gridItemsCount } from '../../constants/channelColors'

import classes from './styles.module.scss'

interface Props {
  popularHoursStatistics: any
  className: string
}

const HourlyChart: React.FC<Props> = ({ popularHoursStatistics: chartData }) => (
  <div className={classes.container}>
    {chartData.averageConversationByTimeOfDay && chartData.averageConversationByTimeOfDay.length ? (
      <>
        <GridChart
          chartData={chartData}
          maxPerHour={chartData.maxPerHour}
          color={color}
          gridItemsCount={gridItemsCount}
        />
        <GradientBar gridItemsCount={gridItemsCount} color={color} step={chartData.maxPerHour / gridItemsCount} />
      </>
    ) : (
      <NoChartData text={noDataText} />
    )}
  </div>
)

const mapStateToProps = (state: { popularHoursStatistics: any }) => ({
  popularHoursStatistics: state.popularHoursStatistics,
})

export default connect(mapStateToProps)(HourlyChart)
