import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import MessageWrap from '../MessageWrap/MessageWrap'

import { styles } from './styles'
import Buttons from '../Buttons/Buttons'

const TextMessageButton = props => {
  const { classes, message, timezone, isReceived, isRtl, highlight } = props

  return (
    <MessageWrap timezone={timezone} isReceived={isReceived}>
      <div className={classes.container} style={{ border: highlight && '1px solid #1658F3' }}>
        <span className={classes.chatBubble} style={{ direction: isRtl && 'rtl' }}>
          {message.text || message.postback}
        </span>
        {message?.buttons && !!message?.buttons?.length && <Buttons buttons={message?.buttons} />}
      </div>
    </MessageWrap>
  )
}

TextMessageButton.propTypes = {
  classes: PropTypes.object,
  text: PropTypes.string,
  timezone: PropTypes.string,
  isReceived: PropTypes.bool,
  isRtl: PropTypes.bool,
}

export default withStyles(styles)(TextMessageButton)
