import { setBotWidget } from '../actions/botWidget'
import { setWidgetDefaultSettings } from '../actions/widgetDefaultSettings'
import { alertError, alertSuccess, createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../api'
import { BASE_URL, BACKEND_URL } from '../../../configs'
import * as cookie from 'react-cookies'

export function getWidget(botId) {
  return fetch(`${BASE_URL}/bot/${botId}/widget`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      setBotWidget(json.data)
    })
    .catch(function(error) {
      alertError('Sorry but something going wrong at getting widget info, please ping support!')
    })
}

export function updateWidget(botId, widget) {
  fetch(`${BASE_URL}/bot/${botId}/widget`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PATCH',
    body: JSON.stringify(widget),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      setBotWidget(json.data)
      alertSuccess('Widget settings updated', 3000)
    })
    .catch(function(error) {
      alertError('Sorry but something going wrong at getting widget info, please ping support!')
    })
}

export function getWidgetDefaultSettings(botId) {
  fetch(`${BASE_URL}/bot/${botId}/widget/default-translations`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET'
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      setWidgetDefaultSettings(json.data)
    })
    .catch(function(error) {
      alertError('Sorry but something going wrong at getting widget info, please ping support!')
    })
}

export function handleDrop(file, callback) {
  fetch(`${BASE_URL}/file/widgetLogo`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: { Authorization: 'Bearer ' + cookie.load('YWxpYWFkbWlu') },
    body: file,
  })
    .then(response => (response.redirected ? (window.location.href = response.url) : response.text()))
    .then(response => {
      callback(response)
    })
    .catch(error => {
      alertError('Fail. Try it later', 10000)
    })
}

export const downloadWidget = botId => {
  window.open(`${BACKEND_URL}/downloadWidget/${botId}`, '_blank')
}
