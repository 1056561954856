import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import TextMessage from '../TextMessage/TextMessage'
import TextMessageButton from '../TextMessageButton/TextMessageButton'
import ImageMessage from '../ImageMessage/ImageMessage'
import AudioMessage from '../AudioMessage/AudioMessage'
import VideoMessage from '../VideoMessage/VideoMessage'
import TemplateMessage from '../CarouselMessage/CarouselMessage'

import { styles } from './styles'

const Message = props => {
  const { msg, timezone, reverse, highlight } = props

  const message = msg?.formattedChatHistory ? JSON.parse(msg.formattedChatHistory) : null
  const timestamp = moment(msg.timestamp).add(timezone, 'hours').format('hh:mm A')

  const isTextMessage = ((message?.text && !message?.buttons?.length) || message?.postback) && !message.imageUrl
  const isTextMessageWithButtons = message?.text && !!message?.buttons?.length
  const isImageMessage = message?.imageUrl
  const isAudioMessage = message?.attachments?.length && message.attachments[0].type === 'audio'
  const isVideoMessage = message?.attachments?.length && message.attachments[0].type === 'video'
  const isTemplateMessage = message?.elements?.length

  const isRtl = message?.isRtl || msg?.isRtl
  const isReceived = reverse ? !message?.isFromUser : message?.isFromUser

  return (
    <>
      {isTextMessage && (
        <TextMessage
          message={message}
          timezone={timestamp}
          isRtl={isRtl}
          isReceived={isReceived}
          highlight={highlight}
        />
      )}

      {isTextMessageWithButtons && (
        <TextMessageButton
          message={message}
          timezone={timestamp}
          isRtl={isRtl}
          isReceived={isReceived}
          highlight={highlight}
        />
      )}

      {isImageMessage && (
        <ImageMessage
          timezone={timestamp}
          isReceived={isReceived}
          imgUrl={message.imageUrl}
          highlight={highlight}
        />
      )}

      {isAudioMessage && (
        <AudioMessage
          timezone={timestamp}
          isReceived={isReceived}
          url={message.attachments[0].payload.url}
          highlight={highlight}/>
      )}

      {isVideoMessage && (
        <VideoMessage
          timezone={timestamp}
          isReceived={isReceived}
          url={message.attachments[0].payload.url}
          highlight={highlight}/>
      )}

      {isTemplateMessage && (
        <TemplateMessage
          timezone={timestamp}
          isRtl={isRtl}
          isReceived={isReceived}
          payload={message}
          highlight={highlight}/>
      )}
    </>
  )
}

Message.propTypes = {
  classes: PropTypes.object,
  timezone: PropTypes.number,
}

const mapStateToProps = state => ({
  timezone: state.activeBot?.timezone,
})

export default withStyles(styles)(connect(mapStateToProps)(Message))
