import { store } from '../../../index'

export const SAVE_MESSAGE = 'SAVE_MESSAGE'
export const SAVE_ACTIVE_SUPPORT_USER = 'SAVE_ACTIVE_SUPPORT_USER'
export const SAVE_SUPPORT_USER_MESSAGES = 'SAVE_SUPPORT_USER_MESSAGES'
export const SAVE_SUPPORT_USER_MORE_MESSAGES = 'SAVE_SUPPORT_USER_MORE_MESSAGES'

export const saveActiveUser = activeUser => {
  store.dispatch({
    type: SAVE_ACTIVE_SUPPORT_USER,
    payload: {
      activeUser,
    },
  })
}

export const saveSupportUserMessages = ({data}) => {
  store.dispatch({
    type: SAVE_SUPPORT_USER_MESSAGES,
    payload: {
      messages: data.content,
    },
  })
}

export const saveSupportUserMoreMessages = ({data}) => {
  store.dispatch({
    type: SAVE_SUPPORT_USER_MORE_MESSAGES,
    payload: {
      messages: data.content,
    },
  })
}

export const saveMessage = message => {
  store.dispatch({
    type: SAVE_MESSAGE,
    payload: {
      message,
    },
  })
}
