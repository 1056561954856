import React, { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { inputChangeEvent, mapIntentSelectStateToProps } from './configs.module'
import { IntentSelectComponentProps, IntentSelectProps, SelectedIntent } from './types.module'
import { customStyles } from '../../../../uiKit/AtomSelect/styles'
import { getAllPagingIntents } from '../../api/nlp'

import classes from './styles.module.scss'

import { createIntentObject, isValueSmallTalk, useSelectIntents } from '../../hooks/useSelectIntents/useSelectIntents'

const IntentsSelect: FC<IntentSelectComponentProps> = ({ match, intents, onElementSelected, value, id }) => {
  const [selectedOption, setSelectedOption] = useState(null as SelectedIntent)
  const [stateIntents, setStateIntents] = useState([])

  useEffect(() => {
    const mappedIntents = useSelectIntents(intents || [])
    if (isValueSmallTalk(value)) mappedIntents.push(getSelectedIntentObject())
    setStateIntents(mappedIntents)
    setSelectedOption(processSelectedOptionForStateChange())
  }, [])

  const botId = (match.params as any).botId

  const handleStateChange = (newOption: SelectedIntent) => {
    setSelectedOption(newOption)
    onElementSelected(newOption)
    getAllPagingIntents(botId)
  }

  const getSelectedIntentObject = () => createIntentObject(value, value, id)

  const modifyIntents = () => {
    return stateIntents.map(intent => ({ ...intent, disabled: isValueSmallTalk(intent.label) }))
  }

  const handleInputChange = (value: string, action) => {
    if (action.action === inputChangeEvent) getAllPagingIntents(botId, value)
  }

  const processSelectedOptionForStateChange = () => (value ? getSelectedIntentObject() : undefined)

  return (
    <div className={classes.containert}>
      <Select
        styles={customStyles}
        options={modifyIntents()}
        value={selectedOption}
        onChange={handleStateChange}
        isSearchable={true}
        onInputChange={handleInputChange}
      />
    </div>
  )
}

export default withRouter<IntentSelectProps, any>(connect(mapIntentSelectStateToProps)(IntentsSelect))
