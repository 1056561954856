import {
  alertError,
  alertSuccess,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import { setSearchAtoms } from '../actions/searchAtoms'
import { editAtom } from '../actions/atom'
import { getFlows } from './flow'
import { BASE_URL } from '../../../configs'
import { formatAtom } from '../../../helpers/atomFormat'

async function createNewAtom(atom, botId) {
  try {
    await fetch(BASE_URL + '/atom', {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify(atom),
    })

    getFlows(botId)
    alertSuccess('Atom is created successfully.')
  } catch {
    alertError('Fail. Try it later')
  }
}

async function saveAtom(atom, botId, language, callback) {
  try {
    const response = await fetch(`${BASE_URL}/atom?language=${language}`, {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'PUT',
      body: JSON.stringify(atom),
    })

    if (response.status === 400) {
      const text = await response.text()
      const res = JSON.parse(text)
      alertError(res.fieldErrors[0]?.message || res.error?.message)
    } else {
      const responseJson = await logoutOnRequestOrResponseJson(response)
      alertSuccess('Atom is updated successfully.')
      const formattedAtom = formatAtom(responseJson?.data)
      editAtom(formattedAtom)
      getFlows(botId)
      callback(formattedAtom)
    }
  } catch {
    alertError('Fail. Try it later')
  }
}

async function getAtom(atomId, language, callback) {
  try {
    const response = await fetch(
      `${BASE_URL}/atom/${atomId}?language=${language}`,
      {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: 'GET',
      },
    )
    const responseJson = await logoutOnRequestOrResponseJson(response)
    const formattedAtom = formatAtom(responseJson?.data)

    if (callback) callback(formattedAtom)
    editAtom(formattedAtom)
  } catch {
    alertError('Fail. Try it later')
  }
}

async function deleteAtom(atomId, botId, callback) {
  try {
    await fetch(`${BASE_URL}/atom/${atomId}`, {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'DELETE',
    })
    getFlows(botId)
    alertSuccess('Atom is deleted succesfully')
    callback()
  } catch {
    alertError('Fail. Try it later')
  }
}

async function searchAtomsByContent(searchQuery) {
  try {
    const response = await fetch(
      `${BASE_URL}/atom/search?query=${searchQuery}`,
      {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: 'GET',
      },
    )
    const responseJson = await logoutOnRequestOrResponseJson(response)
    setSearchAtoms(responseJson?.data)
  } catch {
    alertError('Fail. Try later')
  }
}

async function testApiRequest(body, userId) {
  try {
    const response = await fetch(`${BASE_URL}/api/call/${userId}`, {
      credentials: 'same-origin',
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify(body),
    })

    return await logoutOnRequestOrResponseJson(response)
  } catch {
    alertError('Fail. Try later')
  }
}

export {
  createNewAtom,
  saveAtom,
  getAtom,
  deleteAtom,
  searchAtomsByContent,
  testApiRequest,
}
