import React, { useEffect, useMemo, useState } from 'react'
import Select, { components } from 'react-select'
import ReactTooltip from 'react-tooltip'
import DetectableOverflow from 'react-detectable-overflow'

import { customStyles } from './styles'
import { connect } from 'react-redux'
import classes from './styles.module.scss'
import {OptionItemType} from '../../models/MessageTypes'

interface Props {
  flows: any
  value: string
  touched?: boolean
  onFocus?(event): void
  onChange(value: OptionItemType): void
}

const AtomSelect: React.FC<Props> = ({ flows, value, touched, onFocus, onChange }) => {
  const [options, setOptions]: [OptionItemType[], any] = useState([])
  const [isOverflowed, setIsOverflowed]: [boolean, any] = useState(false)

  const atomValue = useMemo(() => {
    return options.find(({ value: val, label }) => val === value || label === value)
  }, [value, options])

  useEffect(() => {
    if (flows) {
      const options: OptionItemType[] = []

      flows.forEach(({ atoms }: any) => {
        if (atoms) {
          atoms.forEach(({ name, id }: any) => {
            options.push({ label: name, value: id })
          })
        }
      })

      setOptions(options)
    }
  }, [flows])

  const SingleValue = props => {
    return (
      <components.SingleValue {...props}>
        <DetectableOverflow onChange={handleOverflow}>{props.children}</DetectableOverflow>
      </components.SingleValue>
    )
  }

  const handleOverflow = isOverflowed => {
    setIsOverflowed(isOverflowed)
  }

  const handleChange = value => {
    setIsOverflowed(false)
    onChange(value)
  }

  return (
    <span data-tip={atomValue?.label} data-for={atomValue?.label}>
      <Select
        styles={customStyles}
        options={options}
        value={atomValue}
        maxMenuHeight={200}
        minMenuHeight={200}
        menuPlacement={'auto'}
        touched={touched}
        onChange={handleChange}
        onFocus={onFocus}
        components={{ SingleValue }}
      />
      {isOverflowed && atomValue?.label && (
        <ReactTooltip
          className={classes.tooltip}
          effect="solid"
          place="top"
          type="dark"
          scrollHide={true}
          id={atomValue?.label}
        />
      )}
    </span>
  )
}

const mapStateToProps = (state: { flows: any }) => ({
  flows: state.flows,
})

export default connect(mapStateToProps)(AtomSelect)
