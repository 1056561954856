import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import MessageWrap from '../MessageWrap/MessageWrap'

import { styles } from './styles'
import {CORNFLOWER_BLUE} from '../../../../constants/colors'

const TextMessage = props => {
  const { classes, message, timezone, isReceived, isRtl, highlight } = props

  const style = {
    direction: isRtl && 'rtl',
    border: highlight && '1px solid #1658F3',
    backgroundColor: highlight && CORNFLOWER_BLUE
  }

  return (
    <MessageWrap timezone={timezone} isReceived={isReceived}>
      <span className={isReceived ? classes.chatBubbleReceived : classes.chatBubbleSend} style={style}>
        {message.text || message.postback}
      </span>
    </MessageWrap>
  )
}

TextMessage.propTypes = {
  classes: PropTypes.object,
  text: PropTypes.string,
  timezone: PropTypes.string,
  isReceived: PropTypes.bool,
  isRtl: PropTypes.bool,
}

export default withStyles(styles)(TextMessage)
