import { BACKEND_URL } from '../../../configs'
import { alertError, createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../api'
import { setAnalytics, setComments, setMoreComments } from '../actions/analytics'

export function getRatingDistribution({botId, from, to}) {
  const URL = `${BACKEND_URL}/bot/${botId}/analytics/feedback/ratingDistribution?from=${from}&to=${to}`

  return fetch(URL, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(res => setAnalytics(res))
    .catch(() => alertError('Fail. Try it later'))
}

export function getComments({botId, from, to, page, size, sortBy, sortDirection, score}) {
  const params = new URLSearchParams({
    from,
    to,
    page: page || 0,
    size: size || 10,
    sortBy: sortBy || 'date',
    sortDirection: sortDirection || 'desc',
  })

  let URL = `${BACKEND_URL}/bot/${botId}/analytics/feedback/comments?${params}`

  if (score) URL = URL.addQueryStringParameter('score', score)

  return fetch(URL, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(res => setComments(res))
    .catch(() => alertError('Fail. Try it later'))
}

export function getMoreComments({botId, from, to, page, size, sortBy, sortDirection, score}) {
  const params = new URLSearchParams({
    from,
    to,
    page,
    size,
    sortBy,
    sortDirection,
  })

  let URL = `${BACKEND_URL}/bot/${botId}/analytics/feedback/comments?${params}`

  if (score) URL = URL.addQueryStringParameter('score', score)

  return fetch(URL, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(res => setMoreComments(res))
    .catch(() => alertError('Fail. Try it later'))
}
