import { SAVE_ATOMS } from '../actions/atoms'

const _getAtoms = flows => {
  return flows.reduce((sum, flow) => [...sum, ...flow.atoms], [])
}

const atoms = (state = [], { type, payload }) => {
  if (type === SAVE_ATOMS) {
    return _getAtoms(payload.flows)
  } else {
    return state
  }
}

export default atoms
