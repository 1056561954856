import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { LineChart } from 'react-chartkick'

import NoChartData from '../../../../uiKit/NoChartData'
import { channelColors } from '../../constants/channelColors'
import { noDataText } from '../../constants/chartsTexts'
import classes from './styles.module.scss'
import Checkbox from '../../../../uiKit/Checkbox'
import { isObjectEmpty } from '../../../../helpers/isObjectEmpty'
import {sortArrayOfArraysByIndex} from '../../../../helpers/sortArrayOfArraysByIndex'
import {ChartDataParameterType, LinePointType, usersChartDataType} from '../../../../models/DashboardTypes'

interface Props {
  usersStatistics: usersChartDataType
  isMultipleChannels: boolean
}

const getEnabledInLocalStorage = () => {
  const result: string = localStorage.getItem('enabledChannels')
  return result ? result.split(',') : ['All channels']
}

const AreaChart: React.FC<Props> = ({ usersStatistics: chartData, isMultipleChannels }) => {
  const [enabledPlatforms, setEnabledPlatforms] = useState(getEnabledInLocalStorage)

  const getDataForLineChart = (points: Map<string, LinePointType[]>) => {
    const resultData: ChartDataParameterType[] = Object.values(points)[0]
      ?.map(({ platform }, index) => {
        const data = {}
        Object.keys(points).forEach(key => {
          data[key] = points[key][index].userCount
        })
        return { name: platform, data }
      })
      ?.filter(resultEntry => {
        return isMultipleChannels ? enabledPlatforms.includes(resultEntry.name) : resultEntry.name === 'All channels'
      })
    return resultData
  }

  const getColorsForLineChart = (chartData: ChartDataParameterType[]) => {
    return chartData?.map(({ name }) => channelColors.get(name))
  }

  const handleCheckboxChange = (value: boolean, platform: string) => {
    let copyEnabledPlatforms = Object.assign([], enabledPlatforms)
    if (value) copyEnabledPlatforms.push(platform)
    else {
      if (copyEnabledPlatforms.length < 2) return
      copyEnabledPlatforms = copyEnabledPlatforms.filter(e => e !== platform)
    }
    setEnabledPlatforms(copyEnabledPlatforms)
    localStorage.setItem('enabledChannels', copyEnabledPlatforms.toString())
  }

  const getKeysOrderByValuesDesc = (map: Map<string, number>) => {
    return sortArrayOfArraysByIndex(Object.entries(map), 1, 'desc').map(pair => pair[0])
  }

  const lineChartData = useMemo(() => getDataForLineChart(chartData.points), [chartData, enabledPlatforms])
  const colors = useMemo(() => getColorsForLineChart(lineChartData), [lineChartData])
  const linePoints = useMemo(
    () => !isObjectEmpty(chartData.usersBetweenDates) &&
      ['All channels'].concat(getKeysOrderByValuesDesc(chartData.usersBetweenDates)),
    [chartData],
  )
  const isData = useMemo(() => chartData.points && !!Object.keys(chartData.points).length, [chartData])

  return (
    <div className={classes.container}>
      {isData ? (
        <>
          <LineChart data={lineChartData} colors={colors} min={0} legend={false} />

          {isMultipleChannels && (
            <div className={classes.checkboxes}>
              {linePoints.map(platform => (
                <Checkbox
                  key={platform}
                  label={platform}
                  checked={enabledPlatforms.includes(platform)}
                  onChange={(value: boolean) => handleCheckboxChange(value, platform)}
                  color={channelColors.get(platform)}
                />
              ))}
            </div>
          )}
        </>
      ) : (
        <NoChartData text={noDataText} />
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  usersStatistics: state.usersStatistics,
})

export default connect(mapStateToProps)(AreaChart)
