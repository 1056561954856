import { BLUE } from 'constants/colors'

export const createSpan = ({ dataHref, dataExtension, innerText, onclick }) => {
  const spanLink = document.createElement('span')
  spanLink.className = 'linkEdited'
  spanLink.setAttribute('data-href', `${dataHref}`)
  spanLink.setAttribute('contenteditable', 'false')
  spanLink.style.color = BLUE
  spanLink.id = '' + Date.now()
  spanLink.setAttribute('data-extension', `${dataExtension}`)
  spanLink.innerText = innerText
  spanLink.onclick = onclick

  return spanLink
}
