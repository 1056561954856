export const PRIMARY = 'primary'

export const KLEIN_BLUE = '#0030A0'
export const CAMARONE_GREEN = '#006401'
export const GEM_BLUE = '#3B17A2'
export const CINNAMON_YELLOW = '#7D4400'
export const TAMARILLO_RED = '#A21010'
export const SPUN_PEARL_GREY = '#B0B2C0'
export const PERMISSION_RED = '#ff624c'
export const WHITE = '#ffffff'
export const RIBBON_BLUE = '#1658f3'
export const DULL_LAVANDER_BLUE = '#9bb3e4'
export const DANUBE_BLUE = '#547ed0'
export const CORNFLOWER_BLUE = '#5A98F7'
export const BLUE = '#0000FF'
