import React from 'react'
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Popover,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import PostbackAttributes from '../PostbackAttributes/PostbackAttributes'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import AtomSelect from '../../../../uiKit/AtomSelect/AtomSelect'

const styles = theme => ({
  button: {
    marginBottom: theme.spacing.unit * 4,
  },
  typography: {
    margin: theme.spacing.unit * 2,
  },
  popover: {
    borderRadius: 10,
    height: 'auto',
  },
  tab: {
    width: '50%',
    fontFamily: 'Lato, sans-serif',
    minWidth: 134,
  },
  tabIndicator: {
    backgroundColor: '#1890ff',
  },
  input: {
    padding: '10px',
    border: 'none',
    outline: 'none',
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '19px',
    backgroundColor: 'white',
    width: '100%',
  },
  inputHolder: {
    border: '1px solid #C7CAD6',
    width: '100%',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: 5,
    maxHeight: 40,
    minWidth: 220,
  },
  addParametersBtn: {
    marginTop: 10,
    textAlign: 'center',
    color: 'black',
    cursor: 'pointer',
    backgroundColor: 'white',
    height: 40,
    border: '0.5px solid rgb(51, 51, 51)',
    padding: 10,
  },
  popoverButton: {
    width: 'calc(100% - 32px)',
    color: 'rgba(97, 101, 129, 1)',
    fontWeight: 500,
    textAlign: 'center',
    height: 40,
    fontSize: 14,
    border: '1px solid #e5e5e5',
    outline: 'none',
    borderRadius: 10,
    margin: 16,
  },
})

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 16 }}>
      {props.children}
    </Typography>
  )
}

class MessageButton extends React.Component {
  state = {
    open: false,
    anchorOriginVertical: 'top',
    anchorOriginHorizontal: 'center',
    transformOriginVertical: 'top',
    transformOriginHorizontal: 'center',
    positionTop: 200,
    positionLeft: 400,
    anchorReference: 'anchorEl',
    error: false,
  }

  handleChange = (event, value) => {
    const btn = { ...this.props.btn }
    btn.type = value

    if (btn.type === 'postback') {
      btn.payload = ''
      btn.url = undefined
      delete btn.messengerExtensions
    } else if (btn.type === 'web_url') {
      btn.payload = undefined
      btn.url = ''
      btn.messengerExtensions = true
    }
    this.props.onUpdate(btn)
  }
  handleClose = () => {
    this.setState({ open: false })
  }
  anchorEl = null

  handleUpdateAttributes = attributes => {
    const btn = { ...this.props.btn }
    btn.attributes = attributes
    this.props.onUpdate(btn)
  }

  handleChangeButton(value, fieldName) {
    const btn = { ...this.props.btn }
    btn[fieldName] = value
    this.props.onUpdate(btn)
  }

  handleChangeButtonUrl(value) {
    const btn = deepCopyFunction(this.props.btn)
    btn.url = value
    this.props.onUpdate(btn)
  }

  focus = () => {
    this.setState({
      open: true,
    })
  }

  updatePopoverPosition = () => {
    this.setState(
      {
        open: false,
      },
      () => {
        this.setState({
          open: true,
        })
      },
    )
  }

  render() {
    const { btn, rtl } = this.props
    const {
      open,
      anchorOriginVertical,
      anchorOriginHorizontal,
      transformOriginVertical,
      transformOriginHorizontal,
      positionTop,
      positionLeft,
      anchorReference,
      error,
      errorTitle,
    } = this.state
    const { classes } = this.props
    return (
      <div
        onMouseEnter={() => this.setState({ showDelete: true })}
        onMouseLeave={() => this.setState({ showDelete: false })}>
        <input
          type="text"
          className="button"
          placeholder="Button title"
          readOnly
          value={btn.title}
          ref={node => {
            this.anchorEl = node
          }}
          onClick={this.focus}
          style={{
            color: 'transparent',
            textShadow: '0 0 0 #0084ff',
            borderRadius: this.props.last ? ' 0 0 10px 10px' : 0,
            borderBottom: this.props.last ? '0px' : '1px solid #e5e5e5',
          }}
          dir={rtl && 'rtl'}
        />
        <IconButton
          aria-label="Delete"
          style={
            this.state.showDelete
              ? {
                height: '36px',
                position: 'absolute',
                right: -5,
              }
              : { display: 'none' }
          }
          onClick={this.props.onDelete}>
          <img
            style={{ height: 30 }}
            src="/images/platform/delete.svg"
            alt="delete"
          />
        </IconButton>
        <Popover
          open={open}
          onClose={this.handleClose}
          anchorEl={this.anchorEl}
          anchorReference={anchorReference}
          anchorPosition={{ top: positionTop, left: positionLeft }}
          anchorOrigin={{
            vertical: anchorOriginVertical,
            horizontal: anchorOriginHorizontal,
          }}
          transformOrigin={{
            vertical: transformOriginVertical,
            horizontal: transformOriginHorizontal,
          }}
          PaperProps={{
            style: {
              overflow: 'visible',
              borderRadius: 10,
            },
          }}>
          <div className={classes.popover}>
            <input
              type="text"
              className={classes.popoverButton}
              placeholder="Button title"
              value={btn.title}
              style={{
                border: errorTitle && '1px solid #FF624C',
                unicodeBidi: rtl && 'bidi-override',
              }}
              onChange={event =>
                this.handleChangeButton(event.target.value, 'title')
              }
              maxLength={20}
              dir={rtl && 'rtl'}
            />
            <div>
              <div>
                <Tabs
                  classes={{
                    root: classes.tabs,
                    indicator: classes.tabIndicator,
                  }}
                  value={btn.type}
                  onChange={(event, value) => this.handleChange(event, value)}>
                  <Tab
                    classes={{ root: classes.tab }}
                    label="Postback"
                    value="postback"
                  />
                  <Tab
                    classes={{ root: classes.tab }}
                    label="URL"
                    value="web_url"
                  />
                </Tabs>
                {btn.type === 'postback' && (
                  <TabContainer>
                    <AtomSelect
                      value={btn.payload}
                      onChange={({label}) => this.handleChangeButton(label, 'payload')}/>
                    <PostbackAttributes
                      atomAttributes={this.props.btn.attributes}
                      handleUpdateAttributes={this.handleUpdateAttributes}
                      callBack={this.updatePopoverPosition}
                    />
                  </TabContainer>
                )}
                {btn.type === 'web_url' && (
                  <TabContainer>
                    <div
                      style={
                        error
                          ? { borderColor: '#FF624C' }
                          : { borderColor: '#C7CAD6' }
                      }
                      className={classes.inputHolder}>
                      <input
                        className={classes.input}
                        onKeyDown={e => {
                          if (e.keyCode === 13)
                            this.handleChangeButtonUrl(e.target.value)
                        }}
                        onBlur={event => {
                          this.handleChangeButtonUrl(event.target.value)
                        }}
                        defaultValue={btn.url}
                        placeholder="Enter URL"
                      />
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={btn.messengerExtensions}
                          onChange={() => {
                            const btn = { ...this.props.btn }
                            btn.messengerExtensions = !btn.messengerExtensions
                            this.props.onUpdate(btn)
                          }}
                          value="messengerExtensions"
                          color="primary"
                        />
                      }
                      label="Open in Messenger"
                    />
                    <PostbackAttributes
                      atomAttributes={this.props.btn.attributes}
                      handleUpdateAttributes={this.handleUpdateAttributes}
                      callBack={this.updatePopoverPosition}
                    />
                  </TabContainer>
                )}
              </div>
            </div>
          </div>
        </Popover>
      </div>
    )
  }
}

MessageButton.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MessageButton)
