import React, { memo } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import Message from '../Message/Message'

import { styles } from './styles'

const MessageGroup = props => {
  const { classes, messages, date, messageIntoView, messageId, reverse, hash } = props

  const setRef = id => {
    return id?.toString() === hash?.substring(1) || id === messageId ? messageIntoView : null
  }

  return (
    <div className={classes.container}>
      {messages &&
        messages
          .slice()
          .reverse()
          .filter(msg => msg.timestamp.split('T')[0] === date)
          .map(message => (
            <div ref={setRef(message.id)} key={message.id}>
              <Message msg={message} reverse={reverse} highlight={message.id === messageId} />
            </div>
          ))}
    </div>
  )
}

MessageGroup.propTypes = {
  classes: PropTypes.object,
  messages: PropTypes.array,
  date: PropTypes.string,
}

export default memo(withStyles(styles)(MessageGroup))
