import React from 'react'
import TabTemplate from '../../../../../uiKit/TabTemplate'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import FacebookIcon from '../../../../../uiKit/icons/FacebookIcon'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import SubmitButton from '../../../../../uiKit/buttons/SubmitButton'
import Greeting from '../greeting/Greeting'
import FacebookPage from './FacebookPage'
import {
  exchangeShortLiveUserTokenToLongLive,
  getFacebookAppId,
  getFacebookPages,
} from '../../../api/facebook'
import { getBot } from '../../../../home/api/bots'
import Pagination from '../../../../../uiKit/table/Pagination'
import { saveFacebookToken } from '../../../actions/facebookToken'
import { alertError } from '../../../../../api'
import { getFacebookLoggedIn, saveFacebookLoggedIn } from './FacebookConfig'

//TODO: pagination
// import Pagination from "../../nlp/Pagination";

const styles = theme => ({
  title: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: theme.palette.text.header,
  },
  color: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: theme.palette.text.header,
  },
  rootRadio: {
    marginLeft: 13,
    color: theme.palette.primary.iconsGray,
    fontSize: 14,
    fontFamily: 'Lato',
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  pageItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #EBEBEB',
    padding: '16px 0',
    '&:first-of-type': {
      paddingTop: 3, //3px coz it should be 24px according to the design, tab-top itself has 21
    },
    '&:last-of-type': {
      borderBottom: 'none',
      // paddingBottom: 21,           //uncomment this after adding pagination to the pages
      paddingBottom: 0,
    },
  },
  pageInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  pageImg: {
    width: '40px',
    height: '40px',
    marginRight: '16px',
    borderRadius: 100,
  },
  pageName: {
    color: '#3A3F62',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: '500',
    fontFamily: 'Lato, "sans-serif"',
  },
  connectedStatus: {
    fontSize: '14px',
    fontFamily: 'Lato, "sans-serif"',
    fontWeight: '500',
    color: '#43C692',
    marginRight: '24px',
  },
  connectedAnother: {
    fontSize: '14px',
    fontFamily: 'Lato, "sans-serif"',
    fontWeight: '500',
    color: '#3A3F62',
    marginRight: '24px',
  },
  wrapPagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    // borderBottom:`1px solid ${theme.palette.primary.grayColor}`
  },
  checked: {},
  '@global': {
    '.rootRadio label': {
      margin: '-4px 16px -4px -14px',
    },
    '.rootRadio span[class*="label"]': {
      marginLeft: 8,
    },
    '.rootRadio span': {
      fontSize: 14,
      fontFamily: 'Lato',
    },
  },
})

class Facebook extends React.Component {
  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  processFacebookCredentialsAndGetFacebookPages = userFacebookAccessToken => {
    saveFacebookToken(userFacebookAccessToken)
    saveFacebookLoggedIn(true)
    getFacebookPages(this.props.match.params.botId, userFacebookAccessToken)
  }

  processUserAccessToken(accessToken) {
    if (accessToken) {
      exchangeShortLiveUserTokenToLongLive(accessToken)
        .then(response => {
          this.processFacebookCredentialsAndGetFacebookPages(
            response.accessToken,
          )
        })
        .catch(function(error) {
          alertError('Sorry but something going wrong please ping support!')
        })
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      facebookAppId: null,
      rowsPerPage: 10,
    }
  }

  componentDidMount() {
    const facebookLoggedIn = getFacebookLoggedIn()
    getFacebookAppId().then(response => {
      this.setState({
        facebookLoggedIn: facebookLoggedIn,
        facebookAppId: response.appId,
      })
    })
    getBot(this.props.activeBot.id)
  }

  render() {
    const {
      classes,
      derivedClasses,
      settingsPages,
      activeBot,
      expanded,
      handleChangeExpansion,
    } = this.props
    const { page, rowsPerPage, facebookAppId } = this.state
    return (
      <div>
        <TabTemplate
          tab={{
            name: 'Facebook',
            icon: <FacebookIcon />,
            description: 'Connect the chatbot to your Facebook page',
          }}
          button={{
            type: 'sm',
            title: 'Create new Facebook page',
            onClick: e => {
              window.open('https://www.facebook.com/pages/creation', '_blank')
              e.stopPropagation()
            },
          }}
          expanded={expanded}
          onChange={handleChangeExpansion}>
          <div className={derivedClasses.container}>
            {!settingsPages.facebookPages ? (
              <div>
                {facebookAppId === null ? null : (
                  <div className={derivedClasses.tabInner}>
                    <p className={classes.title}>
                      Sign in to Facebook to be able to connect your chatbot
                    </p>
                    <div className={classes.item} style={{ marginRight: '5%' }}>
                      <FacebookLogin
                        appId={facebookAppId}
                        autoLoad={!!this.state.facebookLoggedIn}
                        callback={({ accessToken }) =>
                          this.processUserAccessToken(accessToken)
                        }
                        scope="manage_pages,pages_messaging"
                        render={renderProps => (
                          <SubmitButton
                            title="Sign in"
                            type="secondary"
                            size="sm"
                            onClick={renderProps.onClick}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {settingsPages.facebookPages
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(page => (
                    <div key={page.id}>
                      <FacebookPage
                        disabled={
                          !page.is_webhooks_subscribed &&
                          activeBot.messengerConfigs.pageId !== null &&
                          activeBot.messengerConfigs.pageId != page.id
                        }
                        page={page}
                      />
                    </div>
                  ))}
                {settingsPages.facebookPages &&
                  settingsPages.facebookPages.length > rowsPerPage && (
                  <div className={classes.wrapPagination}>
                    <Pagination
                      page={page}
                      rowsPerPage={rowsPerPage}
                      count={settingsPages.facebookPages.length}
                      onChangePage={this.handleChangePage}
                    />
                  </div>
                )}
              </div>
            )}
            {settingsPages.facebookPages &&
              activeBot.messengerConfigs.accessToken != null && <Greeting />}
          </div>
        </TabTemplate>
      </div>
    )
  }
}

Facebook.defaultProps = {
  classes: {},
}

Facebook.propTypes = {
  classes: PropTypes.object,
  settingsPages: PropTypes.object,
}

const mapStateToProps = state => ({
  settingsPages: state.settingsPages,
  activeBot: state.activeBot,
})

export default withRouter(
  withStyles(styles, { withTheme: true })(connect(mapStateToProps)(Facebook)),
)
