import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import './containers/index.css'
import App from './containers/App'
import reducer from './reducers/index'
import { MuiThemeProvider } from '@material-ui/core/'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import DateFnsUtils from 'material-ui-pickers/utils/date-fns-utils'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { theme } from './constants/theme'
// eslint-disable-next-line no-unused-vars
import * as globalHelpers from './helpers/index'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})
export const store = createStore(reducer, sentryReduxEnhancer)
const history = createBrowserHistory()

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV4Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <BrowserRouter history={history}>
        <MuiThemeProvider theme={theme}>
          <Route path="/" component={App} />
        </MuiThemeProvider>
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  </Provider>,

  document.getElementById('root') || document.createElement('div'),
)
