export const FACELESS_PICTURE = 'https://nrm.co.nz/wp-content/uploads/2017/08/facebook-avatar.jpg'
export const PLATFORM_IMAGE = {
  WEBSITE: '/images/platform/widget-icon.svg',
  FB_MESSENGER: '/images/platform/messenger-icon.svg',
  AMIO_WHATSAPP: '/images/platform/whatsapp-icon.svg',
  DIALOG360_WHATSAPP: '/images/platform/whatsapp-icon.svg',
  TELEGRAM: '/images/platform/telegram.png',
  SMS_MMS_TWILIO: '/images/platform/sms-icon.svg',
  VONAGE: '/images/platform/vonage.png',
}
