import { BASE_URL } from '../../../configs'
import { alertError, createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../api'

export function getMessages(chatId, page) {
  const params = new URLSearchParams({
    page,
    size: '20',
    sort: 'id,desc',
  })

  return fetch(`${BASE_URL}/support/chat/${chatId}?${params}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(() => alertError('Sorry but something going wrong please ping support!', 5000))
}

export function closeUserRequest(json) {
  return fetch(`${BASE_URL}/support/backUserToBot`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(json),
  }).catch(() => alertError('Sorry but something going wrong please ping support!', 5000))
}

export function assignSupportRequest(botId, json) {
  return fetch(`${BASE_URL}/support/assign`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(json),
  }).catch(() => alertError('Sorry but something going wrong please ping support!', 5000))
}

export function getAdminsList(botId) {
  return fetch(`${BASE_URL}/support/admins/${botId}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(() => alertError('Sorry but something going wrong please ping support!', 5000))
}

export function setMessageRead(chatId, messageId) {
  return fetch(`${BASE_URL}/support/read/user/${chatId}/message/${messageId}`, {
    credentials: 'same-origin',
    headers: createJsonHeaders(),
    method: 'PUT',
  }).catch(() => alertError('Sorry but something going wrong please ping support!', 5000))
}
