import cookie from 'react-cookies'

import { showSnackBar } from '../tabs/home/actions/snackBar'
import { logout } from '../tabs/authorization/actions/security'

export function createJsonHeaders() {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json;charset=UTF-8')
  headers.append('Authorization', 'Bearer ' + cookie.load('YWxpYWFkbWlu'))
  return headers
}

export function logoutOnRequestOrResponseJson(response) {
  if (response.ok) {
    if (response.redirected) {
      return logout()
    } else {
      const contentType = response.headers.get('content-type')
      const isJSON = contentType && contentType.indexOf('application/json') !== -1

      return isJSON ? response.json() : response.text()
    }
  }
  throw new Error('Not 2xx response')
}

export function logoutOnRequestOrResponseBlob(response) {
  if (response.ok) {
    return response.redirected ? logout() : response.blob()
  }
  throw new Error('Not 2xx response')
}

export function alertSuccess(message, duration) {
  showSnackBar({
    open: true,
    text: message,
    success: true,
    duration,
  })
}
export const pasteCleanTextContent = (e) => {
  e.preventDefault()
  document.execCommand('insertText', false, e.clipboardData.getData('text/plain'))
}

export const paramsPattern = /{{[^{]*?}}/g
export const ifStringEmpty = (text) => {
  if (text == null)
    return true
  text = text.trim()
  return (text.length === 0 && !text.trim() && text.trim() !== ' ')
}

export function alertError(message) {
  showSnackBar({
    open: true,
    text: message,
    success: false,
  })
}
