import { retryPromiseCallback } from 'helpers/retryCallback/retryCallback'
import { updateSupportUser } from '../../../tabs/support/api/users'
import { UserDTO } from './types'

export const useUpdatedSupportUser = user => {
  const doNeedToFetchUserOneMoreTime = ({ userId, chatId }: UserDTO) => {
    return [userId, chatId].some(field => !field)
  }

  return retryPromiseCallback<UserDTO>(() => updateSupportUser(user), doNeedToFetchUserOneMoreTime)
}
