import React, {useEffect, useMemo, useRef, useState} from 'react'
import { withRouter } from 'react-router-dom'
import 'react-chartjs-2'
import { connect } from 'react-redux'

import AreaChart from './components/AreaChart'
import CircleChart from './components/CircleChart'
import HourlyChart from './components/HourlyChart'
import HeadlineWrapWithDateControl from '../../uiKit/HeadlineWrapWithDateControl'
import AreaChartButton from './components/AreaChartButton'
import CircleChartButton from './components/CircleChartButton'
import HourlyChartButton from './components/HourlyChartButton'
import ChartContainer from '../../uiKit/ChartContainer'
import NamedCounter from './components/NamedCounter'
import ChannelsCircleChart from './components/ChannelsCircleChart'
import ChannelsChartButton from './components/ChannelsChartButton'

import { isObjectEmpty } from '../../helpers/isObjectEmpty'

import { loadSupportStatistics, loadPopularHoursStatistics, loadUsersStatistics } from './api/dashboard'

import {
  areaChartTooltipText,
  channelsChartTooltipText,
  circleChartContainerTitle,
  conversationsCoveredTooltipText,
  hourlyChartContainerTitle,
  hourlyChartTooltipText,
} from './constants/chartsTexts'

import classes from './styles.module.scss'
import LoaderScreen from '../../uiKit/loaders/loaderScreen'
import { usersChartDataType } from 'models/DashboardTypes'

interface Props {
  match: any
  usersStatistics: usersChartDataType
  supportStatistics: any
  popularHoursStatistics: any
}

const Dashboard: React.FC<Props> = ({ match, usersStatistics, supportStatistics, popularHoursStatistics }) => {
  const { botId } = match.params

  const [loader, setLoader] = useState(true)
  const containerRef = useRef(null)

  useEffect(() => {
    containerRef.current.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (usersStatistics && supportStatistics && popularHoursStatistics) {
      setLoader(false)
    }
  }, [usersStatistics, supportStatistics, popularHoursStatistics])

  const platformsNumber = useMemo(() => {
    if (usersStatistics?.points && !isObjectEmpty(usersStatistics?.points)) {
      return Object.values(usersStatistics?.points)[0]?.map(({ platform }) => platform)?.length > 2
    }
  }, [usersStatistics?.points])

  const activeUsersNumber = useMemo(() => {
    if (usersStatistics?.usersBetweenDates && !isObjectEmpty(usersStatistics?.usersBetweenDates)) {
      return Object.values(usersStatistics?.usersBetweenDates).reduce((acc, count) => acc + count, 0)
    }
    return 0
  }, [usersStatistics?.usersBetweenDates])

  return (
    <div className={loader ? classes.containerLoader : classes.container} ref={containerRef}>
      <HeadlineWrapWithDateControl
        title="Dashboard"
        botId={botId}
        loadData={[loadSupportStatistics, loadPopularHoursStatistics, loadUsersStatistics]}
      />
      {loader ? (
        <LoaderScreen />
      ) : (
        <>
          <div className={classes.row}>
            <ChartContainer
              title={'Active users'}
              tooltipText={areaChartTooltipText}
              totalUsers={!platformsNumber && usersStatistics?.totalUsers}
              activeUsers={!platformsNumber && activeUsersNumber}
              button={<AreaChartButton />}>
              <AreaChart isMultipleChannels={!!platformsNumber} />
            </ChartContainer>

            {platformsNumber && (
              <div className={classes.flexBlockColumn}>
                <div className={classes.flexBlock}>
                  <NamedCounter value={activeUsersNumber} title={'Active users'} />
                  <NamedCounter value={usersStatistics?.totalUsers} title={'Total users'} />
                </div>

                <ChartContainer
                  title={'Users per channel'}
                  tooltipText={channelsChartTooltipText}
                  button={<ChannelsChartButton />}>
                  <ChannelsCircleChart />
                </ChartContainer>
              </div>
            )}
          </div>

          <div className={classes.row}>
            <ChartContainer
              title={circleChartContainerTitle}
              tooltipText={conversationsCoveredTooltipText}
              button={<CircleChartButton />}>
              <CircleChart />
            </ChartContainer>

            <ChartContainer
              title={hourlyChartContainerTitle}
              tooltipText={hourlyChartTooltipText}
              button={<HourlyChartButton />}>
              <HourlyChart />
            </ChartContainer>
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state: {
  usersStatistics: usersChartDataType
  supportStatistics
  popularHoursStatistics
}) => ({
  usersStatistics: state.usersStatistics,
  supportStatistics: state.supportStatistics,
  popularHoursStatistics: state.popularHoursStatistics,
})

export default withRouter(connect(mapStateToProps)(Dashboard))
