import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import NavLink from 'react-router-dom/NavLink'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { HOME_PATH } from '../../../../configs'
import { SUPPORT_STATUSES } from './config'
import { saveActiveUser } from '../../actions/activeUser'

import Badge from '../../../../uiKit/Badge/Badge'
import { styles } from './styles'
import UserNameBlock from '../UserNameBlock/UserNameBlock'
import UserIcon from '../../../../uiKit/UserIcon'

const UserDialogPreview = props => {
  const { classes, match, adminUser, activeUser, user } = props

  const isAssigned = user?.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED

  const isAssignedToCurrentAdmin =
    user?.supportRequest?.support?.adminId === adminUser?.id &&
    user?.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED

  const isUnread = user?.unreadMessages && activeUser?.userId !== user?.userId ? 'unread' : ''

  const boldLastMessage = isUnread || (isAssigned && !isAssignedToCurrentAdmin)

  const getLastMessage = user => {
    if (user?.formattedChatHistory) {
      const message = JSON.parse(user.formattedChatHistory)

      return message.text || (message?.elements?.length && 'Template') || (message?.imageUrl && 'Attachment')
    }
    return ''
  }

  const lastMsgText =
    isAssigned && !isAssignedToCurrentAdmin
      ? `Live chat with ${user?.supportRequest?.support?.name}`
      : getLastMessage(user)

  const handleClickUserPreview = () => {
    saveActiveUser(user)
  }

  return (
    <NavLink to={`${HOME_PATH}/bot/${match.params.botId}/support/${user?.userId}`} activeClassName={classes.activeLink}>
      <div className={classes.container} onClick={handleClickUserPreview}>
        <UserIcon platform={user?.platform} image={user?.profilePic}/>

        <div className={classes.userInfo}>
          <UserNameBlock user={user} isAssigned={isAssigned} isAssignedToCurrentAdmin={isAssignedToCurrentAdmin} />

          <div className={classes.lastMsgTextWrap}>
            <p className={!boldLastMessage ? classes.lastMsgText : classes.lastMsgTextUnread}>{lastMsgText}</p>
            {isUnread && <Badge badgeContent={user?.unreadMessages} />}
          </div>
        </div>
      </div>
    </NavLink>
  )
}

UserDialogPreview.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  adminUser: state.adminUser,
})

export default withRouter(withStyles(styles)(connect(mapStateToProps)(UserDialogPreview)))
