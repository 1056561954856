import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import './DateControl.css'
import DateRange from '../DateRange'

const FREQUENCIES = {
  week: 'week',
  month: 'month',
  custom: 'custom',
  hourly: 'hourly',
}

const styles = theme => ({
  tooltip: {
    maxWidth: '80%',
    width: '400px',
    border: '1px solid #EBEBEF',
    boxSizing: 'border-box',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: '10px',
    zIndex: 9999,
    opacity: 1,
  },
})

class Index extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      frequency: FREQUENCIES.week,
      startDate: moment().subtract(1, 'week').add(1, 'day'),
      endDate: moment(),
      hourly: false,
      pickerOpen: false,
      custom: false,
      usersTitle: 'Last 7 days',
    }
  }

  componentDidMount() {
    this.setActiveFrequency(this.state.frequency)
  }

  static getDerivedStateFromProps(props) {
    const { custom } = props

    return custom ? { custom, pickerOpen: true } : { custom: !!custom }
  }

  updateDate = (startDate, endDate, pickerOpen, usersTitle, frequency) => {
    this.setState({
      startDate,
      endDate,
      pickerOpen,
      usersTitle,
      frequency: frequency || this.state.frequency,
    })
  }

  setDate = (startDate, endDate) => {
    const { botId, loadData } = this.props

    if (this.props.setFromDateCallback) this.props.setFromDateCallback(startDate.format('YYYY-MM-DD'))
    if (this.props.setToDateCallback) this.props.setToDateCallback(endDate.format('YYYY-MM-DD'))

    loadData.forEach(request => {
      const params = {botId, from: startDate.format('YYYY-MM-DD'), to: endDate.format('YYYY-MM-DD')}

      if (typeof request === 'function') {
        request(params)
      } else {
        request.request({...params, ...request.params})
      }
    })
  }

  setCustomDate = (startDate, endDate) => {
    const usersTitle = 'Custom'
    this.setState({ startDate, endDate, usersTitle }, () => {
      this.setActiveFrequency(FREQUENCIES.custom)
    })
  }

  setActiveFrequency = frequency => {
    let { startDate, endDate, hourly } = this.state
    let usersTitle, pickerOpen

    switch (frequency) {
    case FREQUENCIES.hourly:
      startDate = moment().subtract(1, 'day')
      endDate = moment()
      hourly = true
      this.setState({ hourly })
      usersTitle = 'Last 24h'
      break
    case FREQUENCIES.week:
      startDate = moment().subtract(1, 'week').add(1, 'day')
      endDate = moment()
      hourly = false
      this.setState({ hourly })
      usersTitle = 'Last 7 days'
      break
    case FREQUENCIES.month:
      startDate = moment().subtract(1, 'month').add(1, 'day')
      endDate = moment()
      hourly = false
      this.setState({ hourly })
      usersTitle = 'Last 30 days'
      break
    case FREQUENCIES.custom:
      pickerOpen = true
      usersTitle = 'Custom'
      hourly = false
      this.setState({ hourly })
      break
    default:
      hourly = false
      this.setState({ hourly })
      pickerOpen = false
      usersTitle = 'Last 7 days'
    }

    this.setState({ pickerOpen, frequency }, () => {
      this.setDate(startDate, endDate, () => {
        if (frequency !== FREQUENCIES.custom) {
          this.updateDate(startDate, endDate, pickerOpen, usersTitle, frequency)
        }
      })
    })
  }

  render() {
    const { custom } = this.props
    const { startDate, endDate, pickerOpen, frequency } = this.state

    return (
      <div className="DateControl">
        <div style={custom ? { display: 'none' } : {}} className="frequency">
          <button
            className={`frequency__button ${frequency === FREQUENCIES.hourly && 'active'}`}
            onClick={() => this.setActiveFrequency(FREQUENCIES.hourly)}>
            Last 24h
          </button>
          <button
            className={`frequency__button ${frequency === FREQUENCIES.week && 'active'}`}
            onClick={() => this.setActiveFrequency(FREQUENCIES.week)}>
            Last 7 days
          </button>
          <button
            className={`frequency__button ${frequency === FREQUENCIES.month && 'active'}`}
            onClick={() => this.setActiveFrequency(FREQUENCIES.month)}>
            Last 30 days
          </button>
          <button
            className={`frequency__button ${frequency === FREQUENCIES.custom && 'active'}`}
            onClick={() => this.setActiveFrequency(FREQUENCIES.custom)}>
            Custom
          </button>
        </div>
        {pickerOpen && <DateRange startDate={startDate} endDate={endDate} setDate={this.setCustomDate} />}
      </div>
    )
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles, { withTheme: true })(Index))
