import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { BrowserRouter, NavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'

import { getBot } from '../../tabs/home/api/bots'
import { HOME_PATH, BACKEND_URL } from '../../configs'
import { getSupportRequests } from '../../tabs/support/api/support'
import { hasPermissionForBot } from '../../security'
import { mainRoutes } from '../../routes/mainRoutes'
import PermissionDenied from '../404/404'
import Loader from '../../uiKit/loaders/loader'
import WebSockets from '../../contexts/webSockets'
import { clearOldBot } from '../../tabs/home/actions/activeBot'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import { UserMenu } from '../../tabs/account/components/UserMenu'
import { clearAttributes } from '../../tabs/settings/actions/attributes'
import { clearWidgetSettings } from '../../tabs/widget/actions/botWidget'
import { ArrowLeft } from '../../uiKit/icons/ArrowLeft'

import { styles } from './styles'

class MainMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      redirectToBots: false,
      activeBot: null,
      ifCreateSocket: false,
      redirectToAccountSettings: false,
    }
  }

  componentDidMount() {
    getBot(this.props.match.params.botId)
    getSupportRequests(this.props.match.params.botId)
  }

  componentWillUnmount() {
    clearOldBot()
    clearAttributes()
    clearWidgetSettings()
  }

  redirectToBots() {
    this.setState({ redirectToBots: true })
  }

  redirectToAccountSettings = () => {
    this.setState({ redirectToAccountSettings: true })
  }

  render() {
    if (this.state.redirectToBots) {
      return <Redirect to={HOME_PATH} />
    }

    if (this.state.redirectToAccountSettings) {
      return (
        <Redirect
          to={{
            pathname: '/account-settings',
            state: { from: this.props.location.pathname },
          }}
        />
      )
    }

    const { classes, activeBot, supportRequests } = this.props
    const pathLink = `${HOME_PATH}/bot/${this.props.match.params.botId}/`
    const pathRoute = `${HOME_PATH}/bot/:botId/`
    const isFunnel = window.location.href.match(/analytics\/\d/g)

    return (
      <div className={classes.root}>
        {this.props.adminUser ? (
          <BrowserRouter>
            <WebSockets adminUser={this.props.adminUser} botId={this.props.match.params.botId}>
              <div className={classes.appFrame}>
                <AppBar position="absolute" className={classes.appBar}>
                  <Toolbar className={classes.Toolbar} disableGutters={!this.state.open}>
                    {isFunnel ? (
                      <NavLink to={`${HOME_PATH}/bot/${this.props.match.params.botId}/analytics`}>
                        <p className={classes.backToAnalytics}>
                          <ArrowLeft /> Back to Analytics
                        </p>
                      </NavLink>
                    ) : (
                      <p onClick={() => this.redirectToBots()} className={classes.homeButton}>
                        Home
                      </p>
                    )}
                    {this.props.activeBot && (
                      <Typography type="title" style={{ fontFamily: 'Lato, sans-serif' }} className={classes.title}>
                        {this.props.activeBot.name}
                      </Typography>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {activeBot && activeBot.messengerConfigs && activeBot.messengerConfigs.pageId && (
                        <a
                          style={{ textDecoration: 'none' }}
                          href={`https://www.messenger.com/t/${this.props.activeBot.messengerConfigs.pageId}`}
                          target="_blank"
                          rel="noreferrer">
                          <Button
                            style={{
                              background: 'linear-gradient(285.2deg, #5A98F7 -35.9%, #1658F3 61.17%)',
                              boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
                              borderRadius: 10,
                              height: 45,
                            }}>
                            <p className={classes.testButton}>Test in Messenger</p>
                          </Button>
                        </a>
                      )}
                      {activeBot && (
                        <a
                          style={{ textDecoration: 'none' }}
                          href={`${BACKEND_URL}/test-widget/${this.props.match.params.botId}`}
                          target="_blank"
                          rel="noreferrer">
                          <Button
                            style={{
                              background: 'linear-gradient(285.2deg, #5A98F7 -35.9%, #1658F3 61.17%)',
                              boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
                              borderRadius: 10,
                              height: 45,
                              margin: '0 30px',
                            }}>
                            <p className={classes.testButton}>Test widget</p>
                          </Button>
                        </a>
                      )}

                      <UserMenu
                        redirectToAccountSettings={this.redirectToAccountSettings}
                        botId={this.props.match.params.botId}
                      />
                    </div>
                  </Toolbar>
                </AppBar>
                <div className={classes.menuWrap}>
                  <List className={classes.list}>
                    {mainRoutes.map(
                      route =>
                        hasPermissionForBot(this.props.match.params.botId, route.path) && (
                          <NavLink
                            key={route.path}
                            className={classes.link}
                            activeClassName={classes.activeLink}
                            to={`${pathLink}${route.path}`}>
                            {route.path === 'support' && supportRequests ? (
                              <div className={classes.supportAmount}>{supportRequests}</div>
                            ) : null}
                            {route.icon}
                            <p className={classes.listItem}>{route.name}</p>
                          </NavLink>
                        ),
                    )}
                  </List>
                </div>
                <Divider />
                <main className={classes.content}>
                  <ErrorBoundary>
                    <Switch>
                      {mainRoutes.map(
                        route =>
                          hasPermissionForBot(this.props.match.params.botId, route.path) && (
                            <Route path={`${pathRoute}${route.path}`} key={route.path} component={route.component} />
                          ),
                      )}
                      <Route component={() => <PermissionDenied botId={this.props.match.params.botId} />} />
                    </Switch>
                  </ErrorBoundary>
                </main>
              </div>
            </WebSockets>
          </BrowserRouter>
        ) : (
          <Loader />
        )}
      </div>
    )
  }
}

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
  supportRequests: state.supportRequests,
  adminUser: state.adminUser,
})

export default withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(MainMenu)))
