import React, { useContext, useEffect, useState } from 'react'
import { SaveTimeContext } from '../../tabs/flowBuilder/components/MessagesWrap/MessagesWrap'
import { usePrevious } from '../../hooks/usePrevious'
import classes from './styles.module.scss'

interface Props {
  value: string
  placeholder: string
  required?: boolean
  onChange(value: string): void
}

const FieldInput: React.FC<Props> = ({
  value,
  placeholder,
  required,
  onChange,
}) => {
  const saveTime = useContext(SaveTimeContext)

  const [touched, setTouched] = useState(false)
  const [focused, setFocused] = useState(false)
  const prevSaveTime = usePrevious(saveTime)

  useEffect(() => {
    if (saveTime && prevSaveTime && saveTime !== prevSaveTime) {
      setTouched(true)
    }
  }, [saveTime])

  useEffect(() => {
    setTouched(false)
  }, [required])

  const handleChange = e => {
    setTouched(true)
    onChange(e.target.value)
  }

  const handleFocus = () => {
    setFocused(true)
    setTouched(true)
  }

  return (
    <input
      className={classes.input}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      style={{
        border: required && touched && !value && !focused && '1px solid red',
      }}
      onFocus={handleFocus}
      onBlur={() => setFocused(false)}
    />
  )
}

export default FieldInput
