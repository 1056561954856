import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { PieChart } from 'react-chartkick'

import NoChartData from '../../../../uiKit/NoChartData'

import { circleChartColors } from '../../constants/channelColors'
import { noDataText } from '../../constants/chartsTexts'

import classes from './styles.module.scss'

interface Props {
  supportStatistics: any
}

const CircleChart: React.FC<Props> = ({ supportStatistics: chartData }) => {
  const { byChatBot, byCustomerSupport } = chartData

  const data = useMemo(() => {
    return [
      ['By chatbot', byChatBot],
      ['By customer support', byCustomerSupport],
    ]
  }, [byChatBot, byCustomerSupport])

  return (
    <div className={classes.container}>
      {byChatBot || byCustomerSupport ? (
        <PieChart data={data} colors={circleChartColors} legend={'bottom'} />
      ) : (
        <NoChartData text={noDataText} />
      )}
    </div>
  )
}

const mapStateToProps = (state: { supportStatistics: any }) => ({
  supportStatistics: state.supportStatistics,
})

export default connect(mapStateToProps)(CircleChart)
